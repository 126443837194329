import { show_layout_modal } from '../vpsb/global';

class VpsIndex {
  init() {
    $('#vps-filter').val('');
    $('#vps-filter')[0].focus({preventScroll: true});

    this.setupVpsFilterChange();
    this.setupDestroyButtons();
  }

  setupVpsFilterChange() {
    $('#vps-filter').on("input", () => {
      var pattern = $('#vps-filter').val().toLowerCase();
      $('.vps-row').each( (index, element) => {
        var vpsHosterName = $(element).children('td.vps-hoster-name').html() || "";
        var planName = $(element).data('plan-name') + "";
        var description = $(element).data('description');
        if ( planName.toLowerCase().includes(pattern)
              || description.toLowerCase().includes(pattern)
              || (vpsHosterName.length>0 && vpsHosterName.toLowerCase().includes(pattern)) ) {
          $(element).removeClass('d-none');
        } else {
          $(element).addClass('d-none');
        }
      });
    });
  }

  setupDestroyButtons() {
    $('.destroy-vps-button').on('click', (event) => {
      event.preventDefault();
      var answer = confirm("Are you sure? All test results for this trial will be deleted. This cannot be undone.");
      if (answer) {
        console.log('ok');
        var vpsId = $(event.currentTarget).data('vps-id');
        this.callDestroyVps(vpsId);
      } else {
        console.log('declined vps destroy');
      }
    });
  }

  callDestroyVps(vpsId) {
    $.ajax({ method: "POST",
      url: `/vps_instances/${vpsId}`,
      data: { "_method": "delete" },
      dataType: 'json',
      success: (data, textStatus, xhr) => {
        console.log("destroy vps success");
        show_layout_modal("The trial and all of its data were destroyed successfully.", undefined, true);
      },
      error: function(data, textStatus, xhr) {
        var respBody = data.responseJSON;
        console.error(`err: ${respBody.error}, code: ${data.status}`);
        show_layout_modal(respBody.error);
      }
    });
  }
}

$(document).on('turbo:load', function() {
  if ($("body.vps_instances.index").length == 0) {
    return;
  }
  console.log("page load in vps_index.js");


  var vps_index = new VpsIndex();
  vps_index.init();
});
